<div id="header" class="header" app-header></div>
<app-hero></app-hero>

<section class="intro vertical-padding">
	<!-- <div class="text-center">
		<div class="black-triangle">&nbsp;</div>
	</div> -->
	<div class="horizontal-padding">
		<div class="container">
			<div class="flex-wrapper biography">
				<div class="bio-copy">
					<h2 class="sub-headline text-center">Who I Am</h2>
					<p class="body-copy text-center" style="margin-bottom: 15px;">I am a digital marketer with 10+ years of experience. My background includes web development, graphic design and most importantly, <strong>direct response marketing</strong>.</p>
					<!-- <p class="body-copy" style="margin-bottom: 15px;">My strategies focus on connecting the marketing side of eCommerce with the web development side. Understanding <strong>Key Performance Indicators</strong> and how that correlates to the code being developed and deployed, as well as the design that is being implemented.</p> -->
					<!-- <p class="body-copy" style="margin-bottom: 15px;">Currently, I am the Director of Technology at Smashtech. I have been with the company for 6 years. I reside in San Diego, CA. I am a huge sports fan, mainly the NFL. I am a car fanatic, especially muscle cars.</p> -->
					<!-- <p class="body-copy" style="margin-bottom: 15px;">I reside in San Diego, CA. I am a huge sports fan, mainly the NFL. I am a car fanatic, especially muscle cars.</p> -->
				</div>
				<div class="bio-image">
					<img src="assets/images/pic-with-car.jpg" />
				</div>
			</div>
			
		</div>
	</div>  
</section>

<section class="intro vertical-padding" style="padding-top: 0;">
	<!-- <div class="text-center">
		<div class="white-triangle">&nbsp;</div>
	</div> -->
	<div class="horizontal-padding">
		<h2 class="sub-headline text-center">What I Do Best</h2>
		<div class="container">
			<div class="flex-wrapper">
				<div class="flex-child intro-item text-center">
					<img src="assets/images/bullseye-icon.png" class="intro-image" />
					 <h3 class="terciary-headline">Direct Response Marketing</h3>
				 	<p class="body-copy">Generate more sales through compelling content. Brands are losing revenue by NOT utilizing direct response marketing.</p>
			 	</div>

				<div class="flex-child intro-item text-center">
					<img src="assets/images/js-logo-white.png" class="intro-image" />
					<h3 class="terciary-headline">Web Development</h3>
					<p class="body-copy">Powerful framework, responsive design, page speed, content distribution.</p>
				</div>

				<div class="flex-child intro-item text-center">
					<img src="assets/images/results-icon-white.png" class="intro-image" />
					<h3 class="terciary-headline">Results</h3>
					<p class="body-copy">The bottom line is always the focus. Determining what drives your economic engine and fueling it.</p>
				</div>

				<a href="#skills" class="cta primary-cta">Increase My Conversions »</a>
		 	</div>
	 	</div>
	 </div>
</section>

<section id="skills" class="skills vertical-padding" style="padding-top: 0;">
	<!-- <div class="text-center">
		<div class="grey-triangle">&nbsp;</div>
	</div> -->
	<div class="horizontal-padding">
		<h2 class="sub-headline text-center">Courses</h2>
		<div class="container">
			<div class="flex-wrapper four">
				<div class="skill text-center">
					<img class="main-img" src="assets/images/cro-graphic-2.jpg" />
					<h2 class="sub-headline text-center">Conversion Rate Optimization</h2>
					<h3 class="terciary-headline">The underground playbook for increasing your conversion rate</h3>
					<p class="ratings"><img class="stars" src="assets/images/stars-5.png" />&nbsp;1,341 Reviews</p>
					<p class="pricing-copy"><del class="reg-price">$99.95</del>&nbsp;<span class="sale-price">$39.95</span>&nbsp;&nbsp;<strong class="savings-copy highlight">(60% Savings - Today Only)</strong></p>
					<p class="body-copy">In this course I share with you how a few tweaks and simple updates can help <strong>increase your convesion rate</strong>.</p>
					<a href="#" class="cta primary-cta">Buy This Course »</a>
					<p class="mbg-copy"><span class="checkmark">&nbsp;&nbsp;</span>30-Day Money Back Guarantee</p>
				</div>

				<div class="skill text-center">
					<img class="main-img" src="assets/images/failed-payments.webp" />
					<h2 class="sub-headline text-center">Failed Payments Recovery</h2>
					<h3 class="terciary-headline">Strategies for salvaging declines & increasing revenue</h3>
					<p class="ratings"><img class="stars" src="assets/images/stars-5.png" />&nbsp;1,137 Reviews</p>
					<p class="pricing-copy"><del class="reg-price">$99.95</del>&nbsp;<span class="sale-price">$49.95</span>&nbsp;&nbsp;<strong class="savings-copy highlight">(50% Savings - Today Only)</strong></p>
					<p class="body-copy">Hoarded by affiliate marketers, this blueprint will <strong>increase your approved transactions and revenue</strong>.</p>
					<a href="#" class="cta primary-cta">Buy This Course »</a>
					<p class="mbg-copy"><span class="checkmark">&nbsp;&nbsp;</span>30-Day Money Back Guarantee</p>
				</div>
			</div>
		</div>	
	</div>	   
</section>

 
 <footer class="footer text-center">
	 <p class="white-text">&copy; 2024 Dollar Roger. All rights reserved.</p>
	 <div class="social-icons">
		 <a href="https://www.linkedin.com/in/roger-sanchez-6270b61a/" target="_blank">
			 <img src="assets/images/li-icon.jpg" class="icon" />
		 </a>
		 <a href="https://www.instagram.com/dollar.roger/" target="_blank">
			 <img src="assets/images/ig-icon.jpg" class="icon" />
		 </a>
	 </div>
 </footer>

<router-outlet></router-outlet>