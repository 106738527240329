<section class="hero black vertical-padding">
	<div class="horizontal-padding">
		<div class="container">
			<!-- <h1 class="hero-headline white-text">Money Makes the Rules</h1> -->
			<h1 class="hero-headline white-text">Stop Leaving Money<br />on the Table</h1>
			<h3 class="subtitle-description white-text text-center">Achieve high conversion rates and maximum return on investement</h3>

			<div class="flex-wrapper hero-benefits text-center">
				<div class="benefit">
					<p><strong>Increased<br />Sales</strong></p>
				</div>
				<div class="benefit">
					<p><strong>*</strong></p>
				</div>
				<div class="benefit">
					<p><strong>Recovered<br />Revenue</strong></p>
				</div>
				<div class="benefit">
					<p><strong>*</strong></p>
				</div>
				<div class="benefit">
					<p><strong>More<br />Profit</strong></p>
				</div>
			</div>
			<a href="#skills" class="cta primary-cta">Increase My Conversions »</a>
		</div>
	</div>
</section>

<div class="featured-on">
	<div class="container">
		<div class="horizontal-padding">
			<h3 class="small-headline text-center">Featured On</h3>
			<img src="assets/images/featured-on.png" />
		</div>
	</div>
</div>